import { SHOW_CHATBASE_ON_PLANS } from '@/slideshow/utils/constant';
import { useEffect, useState } from 'react';
import { useUser } from '@/hooks/useUser';

export const ChatbasePopup = () => {
  const { hasPremium } = useUser();
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.chatbase.co/embed.min.js';
    script.dataset.chatbotId = 'tKLu8vkMXRGg528pHobuO';
    script.dataset.domain = 'www.chatbase.co';
    script.defer = true;

    script.onload = () => {
      console.log('Chatbase script loaded');
      setTimeout(() => {
        console.log('hasPremium', hasPremium);
        if (
          hasPremium !== null &&
          SHOW_CHATBASE_ON_PLANS.includes(hasPremium)
        ) {
          try {
            document.getElementById('chatbase-bubble-button').style.display =
              'block';
            document.getElementById('chatbase-message-bubbles').style.display =
              'block';
          } catch (e) {
            console.log('error setting chatbase', e);
          }
        }
      }, 5000);
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [hasPremium]);

  // Have to remove the chatbase bubble button because the script injection
  // after the DOM loading is not working due to hasPremium delayed update
  // useEffect(() => {
  //   if (
  //     hasPremium !== null &&
  //     SHOW_CHATBASE_ON_PLANS.includes(hasPremium) &&
  //     isScriptLoaded
  //   ) {
  //     try {
  //       document.getElementById('chatbase-bubble-button').style.display =
  //         'block';
  //       document.getElementById('chatbase-message-bubbles').style.display =
  //         'block';
  //     } catch (e) {
  //       console.log('error setting chatbase', e);
  //     }
  //   }
  // }, [hasPremium, isScriptLoaded]);

  return (
    <>
      <style>
        {`
          #chatbase-bubble-button {
            display: none;
          }
          #chatbase-message-bubbles{
            display: none;
          }
        `}
      </style>
      <script
        dangerouslySetInnerHTML={{
          __html: `
                window.embeddedChatbotConfig = {
                    chatbotId: "tKLu8vkMXRGg528pHobuO",
                    domain: "www.chatbase.co"
                };`
        }}
      />
    </>
  );
};
